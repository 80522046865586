import React from 'react';
import styled from 'styled-components';
import Loading from './Components/Loading';

function App() {
  return (
    <AppWrapper>
      <Loading />
    </AppWrapper>
  );
}

const AppWrapper = styled.div`
  min-height: 75vh;
  width: 100%;
  overflow: hidden auto;
  max-width: 800px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export default App;
