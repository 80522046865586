import React from 'react'
import Lottie from 'react-lottie';
import * as animationData from './beer-animation.json'

export default class LottieControl extends React.Component {

  constructor(props) {
    super(props);
    this.state = {isStopped: false, isPaused: false};
  }

  render() {
    const defaultOptions = {
      loop: false,
      autoplay: true,
      animationData: animationData,
      speed: 2,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };

    return <Lottie options={defaultOptions}
              height={150}
              width={150}
              isStopped={this.state.isStopped}
              isPaused={this.state.isPaused}
              style={{position: 'absolute', top: 0, bottom: 0, margin: 'auto', left: 0, right: 0}}
              />
  }
}