import React, { Component } from 'react';
import styled from 'styled-components';

class Button extends Component {
    render() {
        const {icon, onClick, amountChange} = this.props;

        return (
            <StyledButton onClick={() => onClick(amountChange)} disabled={this.props.disabled}>{!this.props.disabled && icon}</StyledButton>
        );
    }
}

const StyledButton = styled.button`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: none;
    width: 2.5rem;
    height: 2.5rem;
    padding: 0;
    text-decoration: none;
    color: #ffffff;
    font-family: sans-serif;
    font-size: 1rem;
    flex-grow: 0;
    flex-shrink: 0;
    cursor: pointer;
    text-align: center;
    transition: all 250ms ease-in-out;
    overflow: hidden;
    margin: 0;
    background: #192b4c;
    width: ${props => props.disabled ? '0rem' : '2.5rem'};
    margin-left: ${props => props.disabled ? '0rem' : '1rem'};

    &:hover, &:active, &:focus, &:visited, &:clicked {
        background: inherit;
    }
`;

export default Button;