import { motion } from 'framer-motion';
import React, { Component } from 'react'
import styled from 'styled-components';
import { getStorage, setStorage } from '../Methods/storage';
import DrinkList from './DrinkList';
import LottieControl from './Lottie';

class Loading extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded: false,
            drinks: [],
            openModal: false,
            startAnimationEnded: false
        }

        this.updateStorage = this.updateStorage.bind(this);
        this.openDelete = this.openDelete.bind(this);
        this.reset = this.reset.bind(this);
        this.setStart = this.setStart.bind(this);
    }

    componentDidMount() {
        this.setStart();
        const drinks = getStorage('drinks');

        this.setState({
            drinks: drinks,
            isLoaded: true
        })
    }
    updateStorage(newDrinks) {
        setStorage('drinks', newDrinks);

        this.setState({
            drinks: newDrinks
        })
    }
    openDelete() {
        const openModal = !this.state.openModal;

        this.setState({
            openModal
        })
    }
    reset() {
        setStorage('drinks', []);

        this.setState({
            drinks: [],
            openModal: false
        })
    }
    setStart() {
        setTimeout(() => {
            this.setState({startAnimationEnded: true})
        }, 1700)
    }
    render() {
        return (
            <>
                {(this.state.isLoaded && this.state.startAnimationEnded)
                    ? <>
                        <DrinkList drinks={this.state.drinks} updateStorage={this.updateStorage}/>
                        <motion.div initial={{opacity: 0}} animate={{ opacity: 1, transition: {delay: 1, duration: 1} }}>
                            {this.state.openModal ? (
                                <CenteredText>
                                    <TextButtons onClick={this.openDelete}>Noch eine Runde!</TextButtons>
                                    <TextButtons onClick={this.reset} color="red">Missetat begangen.</TextButtons>
                                </CenteredText>
                            ) : (
                                <CenteredText onClick={this.openDelete}>Bierdeckel löschen?</CenteredText>
                            )}
                        </motion.div>
                    </>
                    : <LottieControl />
                }
            </>
        );
    }
}



const CenteredText = styled.div`
    font-size: 1rem;
    text-align: center;
    margin-bottom: 2rem;
`;

const TextButtons = styled.div`
    margin: 1rem 0;
    color: ${props => props.color};
`;

export default Loading;