import React, { Component } from 'react'
import styled from 'styled-components';
import Button from './Button';

class Input extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            drink: ''
        };

        this.addDrink = this.addDrink.bind(this);
        this.changeDrink = this.changeDrink.bind(this);
        this.changeName = this.changeName.bind(this);
    }

    changeName(event) {
        this.setState({
            name: event.target.value
        });
    }
    changeDrink(event) {
        this.setState({
            drink: event.target.value
        });
    }

    addDrink(event) {
        if (event) {
            event.preventDefault();
        }

        const {drinks, updateStorage} = this.props;
        const {name, drink} = this.state;

        if(drink === '') {
            return;
        }

        drinks.push({
            drink: drink,
            name: name,
            amount: 1
        });

        updateStorage(drinks);
        this.setState({drink: '', name: ''});
    }

    render() {
        return (
            <ButtonWrapper onSubmit={this.addDrink}>
                <StyledDrink value={this.state.drink} onChange={this.changeDrink} placeholder="Drink*"/>
                <StyledName value={this.state.name} onChange={this.changeName} placeholder="Wer trinkt?"/>
                <Button onClick={this.addDrink} icon="+" disabled={this.state.drink === ''}/>
            </ButtonWrapper>
        );
    }
}

const ButtonWrapper = styled.form`
    display: flex;
`;

const StyledDrink = styled.input`
    width: 70%;
    flex-grow: 1;
    flex-shrink: 1;
    margin-right: 1rem;
`;
const StyledName = styled.input`
    width: 30%;
    flex-grow: 1;
    flex-shrink: 1;
`;

export default Input;