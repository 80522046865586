import React, { Component } from 'react'
import styled from 'styled-components';
import Drink from './Drink';
import Input from './Input';
import { motion } from "framer-motion"

class DrinkList extends Component {
    constructor(props) {
        super(props);

        this.updateDrink = this.updateDrink.bind(this);
    }
    updateDrink(index, amount) {
        const drinks = this.props.drinks;

        if (amount < 1) {
            drinks.splice(index, 1);
        } else {
            drinks[index].amount = amount;
        }

        this.props.updateStorage(drinks);
    }
    render() {
        const list = []

        this.props.drinks.forEach((drink, index) => {
            list.push(
                <motion.div
                    variants={variants.card}
                    key={index}
                >
                    <Drink
                        key={`${drink.drink}_${Math.random().toString(36).substr(2, 5)}`}
                        drink={drink}
                        updateDrink={this.updateDrink}
                        index={index}
                        />
                </motion.div>
            )
        })

        return (
            <ContentWrapper>
                <Header initial={{opacity: 0}} animate={{ opacity: 1, transition: {duration: 1} }}>
                    <Headline>Virtueller Bierdeckel</Headline>
                    <P>Ich schwöre feierlich, ich bin ein Tunichtgut.</P>
                    <Input drinks={this.props.drinks} updateStorage={this.props.updateStorage}/>
                </Header>
                <ListWrapper>
                    <motion.div
                        initial="initial"
                        animate="animate"
                        variants={variants.container}
                        >
                        {list}
                    </motion.div>
                </ListWrapper>
            </ContentWrapper>
        );
    }
}

const variants = {
    container: {
      animate: {
        transition: {
          staggerChildren: 0.1
        }
      }
    },
    card: {
      initial: {
        opacity: 0,
        x: -50
      },

      animate: {
        opacity: 1,
        x: 0
      }
    }
  };

const Header = styled(motion.div)`
  padding: 1rem;
`;

const ContentWrapper = styled.div`

`;

const ListWrapper = styled.div`
    margin-top: 2rem;
`;

const Headline = styled.h1`
    font-size: 2rem;
    line-height: 1.2;
    margin-bottom: 1rem;
    margin-top: 0;
    text-align: center;
    font-weight: normal;
`;

const P = styled.p`
    margin: 0 0 2rem 0;
    text-align: center;
`;

const AnimatedDrink = styled(Drink)`

`;
export default DrinkList;